import { createTheme, hexToRgb } from '@mui/material/styles';
import { red, grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#47C0B6',
    },
    secondary: {
      main: grey[700],
      light: grey[300],
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    white: {
      main: "#ffffff",
      focus: "#ffffff",
    },
  
    black: {
      light: "#000000",
      main: "#000000",
      focus: "#000000",
    },
    grey: {
      100: "#f8f9fa",
      200: "#f0f2f5",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#6c757d",
      700: "#495057",
      800: "#343a40",
      900: "#212529",
    },
    green :{
      100: "#c8e6c9",
      200: "#a5d6a7",
      300: "#81c784",
      400: "#66bb6a",
      500: "#4caf50",
      600: "#43a047"
    }
  },
  typography: {
    fontSize: 12,
    fontFamily: "Radical W03",
    h6: {
      fontSize: '1.10em',
    },
  },
  functions: {
    hexToRgb
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': "Radical W03",
        'font-display': 'swap'
      },
    },
  },
});

export default theme;
