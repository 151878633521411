import React from "react"
import { UserProvider } from "./src/context/UserContext"
import { DialogProvider } from "./src/context/DialogContext"
import { LoadingProvider } from "./src/context/LoadingContext"
import { ThemeProvider } from "@mui/material/styles"
import themePiramide from "./src/styles/ThemePiramide"
import ThemeOceanica from "./src/styles/ThemeOceanica"
import CssBaseline from "@mui/material/CssBaseline"
import "./src/styles/global.css"
import "./src/components/material-kit-pro-react/scss/material-kit-pro-react.scss"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import TagManager from "react-gtm-module"

const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY
const GTMID = process.env.GATSBY_GTM_ID
const activeEnv = process.env.NODE_ENV

export const wrapRootElement = ({ element }) => {
  const tagManagerArgs = {
    gtmId: GTMID,
  }

  if (insuranceCompany !== "OCEANICA") {
    TagManager.initialize(tagManagerArgs)
  }
  
  return (
    <UserProvider>
      <DialogProvider>
        <ThemeProvider
          theme={
            insuranceCompany === "OCEANICA" ? ThemeOceanica : themePiramide
          }
        >
          <CssBaseline />
          <LoadingProvider>
            <PayPalScriptProvider>{element}</PayPalScriptProvider>
          </LoadingProvider>
        </ThemeProvider>
      </DialogProvider>
    </UserProvider>
  )
}

// export const onServiceWorkerUpdateReady = () => {
//   window.location.reload(true)
// }
