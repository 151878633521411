import React, { Fragment } from 'react'
import { CircularProgress } from '@mui/material';
import { Backdrop } from '@mui/material';


const loadingZone = {
    position: 'fixed',
    bottom: '50%',
    right: '50%',
    zIndex: 3000
}

const LoadingContext = React.createContext();

export function LoadingProvider ({ children })  {
    
    const [loading, setLoading] = React.useState(false);

    const  openLoading = (visible) => {
        setLoading(visible)
    }

    return(
        <Fragment>
            <LoadingContext.Provider
                children={children}
                value={openLoading}
            />
            {loading &&     
                <div style={loadingZone}>
                    <Backdrop open={loading}>
                        <CircularProgress color="primary"/>
                    </Backdrop>
                </div>
            }
        </Fragment>
    )
}

export function useLoading(){
    const context = React.useContext(LoadingContext);
    return context;
}